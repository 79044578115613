<template>
    <div>
        <SectionHead />
        <div class="container-fluid container-head" style="position: relative;bottom: 75px;">
            <div class="row justify-content-center">
                    <div class="col-md-3">
                        <label for="emailExample" class="text-gray-600 small">Email </label>
                        <input type="text" placeholder="" v-model="emailUser" id="nombre" aria-label="nombre" class="form-control form-control-solid send-data">
                    </div>
                    <div class="col-md-3" style="margin-top: 8px;">
                        <button class="btn  btn-buscar btn-buscar-reponsive mt-4 lift" style="font-size:15px;color:white;" @click="getOrders()">Buscar <i class="fa-solid fa-magnifying-glass"></i></button>
                    </div>
                <div class="col-md-8 d-flex flex-column align-self-stretch" v-if="pedidos && pedidos.length > 0">
                    <section class="mt-5">
                        <table id="tablaPedidos" class="table table-hover table-striped table-condensed table-bordered table-mobile">
                            <thead class="text-center">
                                <th style="width: 6%;">Pedido</th>
                                <th>Fecha del Pedido</th>
                                <th>Estado</th>
                                <th style="width:8%">Precio</th>
                                <th>Detalle</th>
                            </thead>
                            <tbody>
                                <tr class="pt-2 pb-2" v-for="(pedido,index) in this.pedidos" v-key="index">
                                    <td class="align-middle">#{{ pedido.id }}</td>
                                    <td class="text-center align-middle">{{ this.dateFormat(pedido.fecha_ingreso) }}</td>
                                    <td class="text-center align-middle">{{ this.getEstado(pedido.estado) }}</td>
                                    <td class="text-end align-middle">{{ this.formatearMoneda(pedido.precio_total) }}</td>
                                    <td class="text-center">
                                        <a class="btn-detail btn btn-primary color-btn lift p-2 mt-3 me-2 text-center m-2" @click="openDetail(JSON.parse(pedido.pedido_json).order)" style="border:none;">
                                            <i class="fas fa-info-circle"></i>
                                        </a>
                                    </td>
                                </tr>                       
                            </tbody>
                        </table>
                        <h6 id="resultadoPedido" class="text-center" style="font-size: 20px;display: none;"> No se encontraron elementos para su b&uacute;squeda</h6>
			        </section>
                </div>
            </div>
        </div>
        <ModalDetailOrder  :data="this.detailOrder" v-if="this.isVisibleOrder" />
    </div>
</template>
  
<script>
import SectionHead from '@/components/order/Section.vue'
import store from '@/store/index.js';
import tioTotoService from '../service/data.service'
import ModalDetailOrder from '../components/ModalDetailOrder.vue'

export default {
    name: 'pedidos',
    components: {
        SectionHead,ModalDetailOrder
    },
    mounted() {
    },
    data() {
        return {
            pedidos : [],
            emailUser: '',
            detailOrder:null,
            isVisibleOrder: true
        }
    },
    methods: {
        getOrders: async function () {
            try {
                if(this.emailUser == ""){
                    throw("El email no puede estar vacio");
                }
                store.dispatch("setSpinner", true);
                const {
                    status,
                    data
                } = await tioTotoService.userOrder(this.emailUser);

                if(data.length == 0){
                    this.pedidos = [];
                    throw("No se encontraron pedidos con el email ingresado");
                }
                this.pedidos = data;
            } catch (error) {
                this.$swal("Tío Toto", error, "info");
            } finally {
                store.dispatch("setSpinner", false);
            }
        },
        getEstado(estado){
            if(estado == "PAGO APROBADO"){
                return "PENDIENTE DE IMPRESIÓN";
            }
            if(estado == "COMPLETADO"){
                return "COMPLETADO";
            }
            return estado;
        },
        formatearMoneda(value) {
            let val = (value / 1).toFixed(0).replace(".", ",");
            return "$" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        dateFormat(dateIn){
            const date = new Date(dateIn);
            const day = ("0" + date.getDate()).slice(-2);
            const month = ("0" + (date.getMonth() + 1)).slice(-2);
            const year = date.getFullYear().toString();

            return `${day}/${month}/${year}`;
           
        },
        openDetail(data){
            this.isVisibleOrder = true;
            this.detailOrder = data;
            setTimeout(() => {
                    jQuery("#modalDetailOrder").modal("toggle");
            }, 100);
        }
    }
}
</script>
  
<style scoped>

.btn-detail{
    background-color: #61af72;
}
</style>
  